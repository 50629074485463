.controls {
  opacity: 0;
}

.grid {
  display: grid;
  grid-gap: 1px;
  margin-top: 1px;
  margin-bottom: 4px;
}

.gridItem {
  outline-style: solid;
  outline-width: 1px;
  position: relative;
  width: 100%;

 &:hover .controls {
    opacity: 1 !important;
  }
}
