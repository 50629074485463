.masonryViewItem {
  border-width: 0;
  border-top-width: 1px;
  width: 100%;
  /* border-left: 0; */

  @media (min-width: theme('screens.iphone13pm')) and (max-width: theme('screens.md')) {
    border-right-width: 1px;

    &:nth-child(-n + 2) {

      /*
       border-top: 1px solid var(--grid-item-border-color); 
       */
    }

    &:nth-child(2n) {
      border-right-width: 0;
    }
  }


}

.masonryColumn {
  border-color: #ebebeb;

  @screen lg {
    border-left-width: 1px;
    width: 50%;

    &:nth-child(1) {
      border-left-width: 0;
    }
  }
}
