.slider {
  &:active .tooltip {
    opacity: 1;
  }
}

.tooltip {
  opacity: 0;
}

.thumb:active {
  div {
    opacity: 1;
  }
}
